import React from "react";
import { Link } from "react-router-dom";
import Image1 from "../images/backgrounds/faq.jpg";
import Image2 from "../images/shapes/contact-two-shape-1.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Fade } from "react-reveal";
import PageHeader from "../component/PageHeader";
// const FaqImage = require('../images/backgrounds/page-header-bg.jpg');

const Faq = () => {
  return (
    <>
      <PageHeader img={Image1} heading="Faqs" />
      {/* <!--Faq Page Start--> */}
      <section className="faq-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="faq-page__single">
                <Fade bottom>
                  <Accordion
                    className="accrodion-grp faq-one-accrodion"
                    allowZeroExpanded
                    allowMultipleExpanded={false}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Maecenas scelerisque nibh vitae turpis
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="inner">
                          <p>
                            Aliquam viverra arcu. Donec aliquet blandit enim
                            feugiat. Suspendisse id quam sed eros tincidunt
                            luctus sit amet eu nibh egestas tempus turpis, sit
                            amet mattis magna varius non.
                          </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </Fade>
                <Fade bottom>
                  <Accordion
                    className="accrodion-grp faq-one-accrodion"
                    allowZeroExpanded
                    allowMultipleExpanded={false}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Maecenas scelerisque nibh vitae turpis
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="inner">
                          <p>
                            Aliquam viverra arcu. Donec aliquet blandit enim
                            feugiat. Suspendisse id quam sed eros tincidunt
                            luctus sit amet eu nibh egestas tempus turpis, sit
                            amet mattis magna varius non.
                          </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </Fade>

                <Fade bottom>
                  <Accordion
                    className="accrodion-grp faq-one-accrodion"
                    allowZeroExpanded
                    allowMultipleExpanded={false}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Maecenas scelerisque nibh vitae turpis
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="inner">
                          <p>
                            Aliquam viverra arcu. Donec aliquet blandit enim
                            feugiat. Suspendisse id quam sed eros tincidunt
                            luctus sit amet eu nibh egestas tempus turpis, sit
                            amet mattis magna varius non.
                          </p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </Fade>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <Fade bottom>
                <Accordion
                  className="accrodion-grp faq-one-accrodion"
                  allowZeroExpanded
                  allowMultipleExpanded={false}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Maecenas scelerisque nibh vitae turpis
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="inner">
                        <p>
                          Aliquam viverra arcu. Donec aliquet blandit enim
                          feugiat. Suspendisse id quam sed eros tincidunt luctus
                          sit amet eu nibh egestas tempus turpis, sit amet
                          mattis magna varius non.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Fade>
              <Fade bottom>
                <Accordion
                  className="accrodion-grp faq-one-accrodion"
                  allowZeroExpanded
                  allowMultipleExpanded={false}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Maecenas scelerisque nibh vitae turpis
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="inner">
                        <p>
                          Aliquam viverra arcu. Donec aliquet blandit enim
                          feugiat. Suspendisse id quam sed eros tincidunt luctus
                          sit amet eu nibh egestas tempus turpis, sit amet
                          mattis magna varius non.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Fade>

              <Fade bottom>
                <Accordion
                  className="accrodion-grp faq-one-accrodion"
                  allowZeroExpanded
                  allowMultipleExpanded={false}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Maecenas scelerisque nibh vitae turpis
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="inner">
                        <p>
                          Aliquam viverra arcu. Donec aliquet blandit enim
                          feugiat. Suspendisse id quam sed eros tincidunt luctus
                          sit amet eu nibh egestas tempus turpis, sit amet
                          mattis magna varius non.
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Faq Page End--> */}

      {/* <!--Contact Two Start--> */}
      <section className="contact-two">
        <div
          className="contact-two__shape-1"
          style={{ backgroundImage: `url(${Image2})` }}
        ></div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-4 col-lg-4">
                <div className="contact-two__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">contact us</span>
                    <h2 className="section-title__title">
                      feel free to get in touch
                    </h2>
                  </div>
                  <div className="contact-two__call">
                    <div className="contact-two__call-icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="contact-two__call-content">
                      <p className="contact-two__number-email">
                        <Link to="tel:9200368090">+92 (003) 68-090</Link> <br />
                        <Link to="mailto:info@urbanhomes.com">info@urbanhomes.com</Link>
                      </p>
                    </div>
                  </div>
                  <p className="contact-two__text">
                    30 Commecial Broklyn Road <br /> Fratton, Australia
                  </p>
                </div>
              </div>
            </Fade>

            <Fade right>
              <div className="col-xl-8 col-lg-8">
                <div className="contact-two__right">
                  <form className="contact-two__form contact-form-validated">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="contact-two__input-box">
                          <input
                            type="text"
                            placeholder="Your name"
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="contact-two__input-box">
                          <input
                            type="email"
                            placeholder="Email address"
                            name="email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="contact-two__input-box text-message-box">
                          <textarea
                            name="message"
                            placeholder="Write message"
                          ></textarea>
                        </div>
                        <Fade bottom>
                          <div className="contact-two__btn-box">
                            <button
                              type="submit"
                              className="thm-btn contact-two__btn"
                            >
                              send a message
                            </button>
                          </div>
                        </Fade>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      {/* <!--Contact Two End--> */}
    </>
  );
};
export default Faq;
