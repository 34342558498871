
import * as yup from "yup";
export const qouteform = yup
  .object({
    first_name: yup.string().required("First name is required"),
    // last_name: yup.string().required("Last name is required"),
    square_feet: yup.string().required("Required"),
    email: yup.string().required("Email is Required").email(),
    stairwell_basement: yup.string().required("Required"),
    bedrooms: yup.string().required("Required"),
    rec_rooms: yup.string().required("Required"),
    bathrooms: yup.string().required("Required"),
    storage_rooms: yup.string().required("Required"),
    doors: yup.string().required("Required"),
    bar_or_kitchen: yup.string().required("Required"),
    fireplace: yup.string().required("Required"),
    legal_sec_suit: yup.string().required("Required"),
    basement_foot: yup.string().required("Required"),
    basement_windows: yup.string().required("Required"),
    approximate_year: yup.string().required("Required"),
    special_notes: yup.string().required("Required"),
    // phone: yup.string(),
  })
  .required();