import Avatar from "../images/resources/contact-one-person-img-1.jpg";
import Fade from "react-reveal/Fade";
import Image24 from "../images/backgrounds/contact-form-bg-img-1.jpg";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section className="contact-one">
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-7 col-lg-8">
                <div className="contact-one__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      visit the property
                    </span>
                    <h2 className="section-title__title">Request a visit</h2>
                  </div>
                  <div className="contact-one__person">
                    <div className="contact-one__person-info">
                      <div className="contact-one__person-img">
                        <img src={Avatar} alt="" />
                      </div>
                      <div className="contact-one__person-content">
                        <h3 className="contact-one__person-name">
                          Kevin Smith
                        </h3>
                        <p className="contact-one__person-sub-title">
                          Certified agent
                        </p>
                      </div>
                    </div>
                    <div className="contact-one__person-text">
                      <p>
                        Lorem ipsum dolor sit amet, consecte adipiscing elit. In
                        hac habitasse platea dictumst. Duis porta ultrices.
                      </p>
                    </div>
                  </div>
                  <ul className="contact-one__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="fas fa-phone-alt"></span>
                      </div>
                      <div className="content">
                        <p>Have any question?</p>
                        <h4>
                          <Link to="tel:+9288008960">
                            Free +92 (8800) - 8960
                          </Link>
                        </h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="fas fa-envelope"></span>
                      </div>
                      <div className="content">
                        <p>Write email</p>
                        <h4>
                          <Link to="mailto:info@urbanhomes.com">
                            info@urbanhomes.com
                          </Link>
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-5 col-lg-4">
                <div className="contact-one__right">
                  <div className="contact-one__form-box ">
                    <div
                      className="contact-form-bg"
                      style={{ backgroundImage: `url(${Image24})` }}
                    ></div>
                    <div className="section-title text-left">
                      <span className="section-title__tagline">
                        contact with me
                      </span>
                      <h2 className="section-title__title">write to agent</h2>
                    </div>
                    <form
                      className="contact-one__form contact-one-validated"
                      novalidate="novalidate"
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="contact-one__input-box">
                            <input
                              type="text"
                              placeholder="Your Name"
                              name="name"
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="contact-one__input-box">
                            <input
                              type="email"
                              placeholder="Email Address"
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="contact-one__input-box">
                            <input
                              type="email"
                              placeholder="Phone Number"
                              name="email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="contact-one__input-box text-message-box">
                            <textarea
                              name="message"
                              placeholder="Write a Message"
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="thm-btn contact-one__btn"
                          >
                            Send a message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
