import React from "react";
import PageHeader from "../../component/PageHeader";
import Image1 from "../../images/services/services.png";
import Contact from "../../component/Contact";

const Services = () => {
  return (
    <>
      <PageHeader img={Image1} heading="Contact Us" />
    
     <br/>
     <br/>
     <br/>
      <Contact/>
    </>
  );
};
export default Services;
