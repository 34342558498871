import React from "react";
import Feedback from "../component/Feedback";
import PageHeader from "../component/PageHeader";
import Image1 from "../images/backgrounds/review.jpg";
import T3 from "../images/testimonial/testimonial-1-4.jpg";
import D1 from "../images/testimonial/testimonial-client-1-2.jpg";
import D2 from "../images/testimonial/testimonial-client-1-3.jpg";
import Image13 from "../images/resources/cta-two-bg-img-1.jpg";
import S1 from "../images/shapes/testimonial-one-shape-1.png";
import S2 from "../images/shapes/testimonial-one-shape-2.png";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import Contact from "../component/Contact";
const Review = () => {
  const Data = [
    {
      img: D1,
      name: "kevin Smith",
      shape: S1,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
    {
      img: D2,
      name: "kevin Smith",
      shape: S2,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
    {
      img: T3,
      name: "kevin Smith",
      shape: S1,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
  ];
  return (
    <>
      <PageHeader img={Image1} heading="Review" />
      <section>
        <div className="container mt-4">
          <div className="row">
            <Fade left>
              <h1 className="section-title__title">BOOKING.COM AWARD 2022</h1>
            </Fade>

            <Fade bottom>
              <p className="values-one__text">
                So very proud to announce that we’ve won a Booking.com
                Traveller/Guest Review Award again for 2022 🏆 <br />
                These are some of our new build apartments that are performing
                exceptionally well with great interior design done in-house! ⭐️
                <br />
                <br />
                <br />
                Being an Official Booking.com Partner is amazing, as this really
                is the best platform to generate bookings for a variety of guest
                types from corporates, families, workers to couples travelling.
                Next year we are aiming to achieve more success! 🤞🏻
                <br />
                <br />
                The Booking.com Traveller Review Awards is an annual
                appreciation program that recognises Booking.com partners for
                their exceptional hospitality, as evidenced in the review scores
                left by guests after their stays.
              </p>
            </Fade>
          </div>
        </div>
      </section>
      <Feedback />
      <section>
        <div className="container mt-4">
          <div className="row">
            <Fade left>
              <h1 className="section-title__title">LIVE REVIEWS</h1>
            </Fade>

            <Fade bottom>
              <p className="values-one__text">
                Below are some reviews from our Booking.com/Airbnb listings,
                these are taken directly from the platform so they are 100%
                authentic. We take every review with pride, we aim to
                over-deliver on all our services and really make sure the
                guest/client leaves happy!
                <br />
                <br />
                If there is anything less than a 10 star review or 5 star we
                will rectify the concern so that the next guest has a better
                guest experience.
              </p>
            </Fade>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-4">
          <Fade bottom>
            <div className="row">
              {Data.map((e) => (
                <div class="services-one__single col-md-4">
                  <div className="services-one__single-inner">
                    <div className="services-one__icon">
                      <img
                        src={e.img}
                        className="rounded-circle"
                        alt="review"
                      />
                    </div>
                    <h3 className="services-one__title">
                      <Link to="">{e.name}</Link>
                    </h3>
                    <p className="services-one__text">{e.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </Fade>
          <Fade bottom>
            <div className="row d-flex justify-content-center">
              <div class="product__all-btn-box" style={{ display: "contents" }}>
                <a href="https://apartmentsde-luxe.zeevou.direct/" class="thm-btn product__all-btn2">
                  Book Appointment
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section className="cta-two cta-four mt-3">
        <div
          className="cta-two__bg"
          style={{ backgroundImage: `url(${Image13})` }}
        >
          <div id="jarallax-container-0">
            <div style={{ backgroundImage: `url(${Image13})` }}></div>
          </div>
        </div>
        <div className="cta-two__inner">
          <h3 className="cta-two__title">Schedule a building visit</h3>
          <p className="cta-two__text">
            Lorem ipsum dolor sit amet, cotur adiping elit
          </p>
          <div className="cta-two__btn-box">
            <Link to="" className="cta-two__btn thm-btn">
              Send a request
            </Link>
          </div>
        </div>
      </section>
      <br/>
      <Contact/>
    </>
  );
};

export default Review;
