import React, { Component } from "react";
import { Link } from "react-router-dom";
class BackToTop extends Component {
  state = {
    is_visible: false,
  };

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <>
        <Link
          to="#"
          data-target="html"
          class="scroll-to-target scroll-to-top"
          onClick={() => this.scrollToTop()}
        >
          {is_visible && <i class="icon-up-arrow"></i>}
        </Link>
      </>
    );
  }
}
export default BackToTop;
