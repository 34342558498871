import React from "react";

const PageHeader = (...props) => {
//   console.log(props[0]);
  return (
    <>
      {/* <!--Page Header Start--> */}
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(" + props[0].img + ")" }}
        ></div>
        <div className="page-header__overly"></div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              {/* <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li> */}
              {/* <li>{props[0].heading}</li> */}
            </ul>
            <h2>{props[0].heading}</h2>
            <p className="text-white">{props[0]?.name}</p>
          </div>
        </div>
      </section>
      {/* <!--Page Header End--> */}
    </>
  );
};
export default PageHeader;
