import React from "react";
import PageHeader from "../component/PageHeader";
import Image1 from "../images/backgrounds/accomodation.jpeg";
import Image2 from "../images/shapes/new-concept-two-shape-1.png";
import Image3 from "../images/shapes/new-concept-two-shape-2.png";
import Image4 from "../images/resources/new-concept-two-img-1.jpg";
import Image5 from "../images/shapes/services-one-shape-1.png";
import Image6 from "../images/shapes/services-one-shape-2.png";
import Image7 from "../images/backgrounds/services-one-single-bg.jpg";
import Image8 from "../images/backgrounds/services-one-single-bg.jpg";
import Image9 from "../images/backgrounds/services-one-single-bg.jpg";
import Image10 from "../images/backgrounds/services-one-single-bg.jpg";
import Image11 from "../images/backgrounds/services-one-single-bg.jpg";
import Image12 from "../images/backgrounds/services-one-single-bg.jpg";
import Image13 from "../images/resources/cta-two-bg-img-1.jpg";
import Fade from "react-reveal";
import Brand from "../component/Brand";
import { Link } from "react-router-dom";
import Gallery from "../component/Gallery";
const Accomodation = () => {
  return (
    <>
      <PageHeader
        img={Image1}
        heading="Gallery"
        name="Luxury Serviced Apartments In Birmingham ✓"
      />
      <Gallery />
      <section className="new-concept-two">
        <div className="new-concept-two__shape-1 float-bob-y">
          <img src={Image2} alt="" />
        </div>
        <div className="new-concept-two__shape-2 float-bob-x">
          <img src={Image3} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-5 col-lg-6">
                <div className="new-concept-two__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Good lifestyle
                    </span>
                    <h2 className="section-title__title">
                      A New Concept In our apartments
                    </h2>
                  </div>
                  <p className="new-concept__text">
                    There are many variations of passages of available, but the
                    majority have suffered alteration in some form.
                  </p>
                  <ul className="new-concept__points list-unstyled">
                    <li>
                      <div className="icon">
                        <span className="icon-manhattan"></span>
                      </div>
                      <div className="text">
                        <h3>Luxury Living Experience</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="icon-apartment-1"></span>
                      </div>
                      <div className="text">
                        <h3>Center At City Downtown</h3>
                        <p>
                          Services address a range of simply free aplication and
                          infrastructure needs.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="col-xl-7 col-lg-6">
                <div className="new-concept-two__right">
                  <div className="new-concept-two__img">
                    <img src={Image4} alt="" />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <Brand />
      <section className="services-one">
        <div className="services-one__shape-1 float-bob-x">
          <img src={Image5} alt="" />
        </div>
        <div className="services-one__shape-2 float-bob-x">
          <img src={Image6} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <Fade left>
              <div className="col-xl-6 col-lg-12">
                <div className="services-one__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Apartments Amenities
                    </span>
                    <h2 className="section-title__title">
                      STUNNING LUXURY RENTAL APARTMENTS, DESIGNED FOR LIFE
                    </h2>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="" className="thm-btn services-one__btn">
                      schedule a visit
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="services-one__single">
                  <div className="services-one__single-inner">
                    <div
                      className="services-one__single-bg"
                      style={{ backgroundImage: `url(${Image7})` }}
                    ></div>
                    <div className="services-one__icon">
                      <span className="icon-residential"></span>
                    </div>
                    <h3 className="services-one__title">
                      <Link to="">Smart Living</Link>
                    </h3>
                    <p className="services-one__text">
                      There are many new ways of text.
                    </p>
                  </div>
                </div>
                <div
                  className="services-one__btn-box mt-3"
                  style={{ Display: "flex" }}
                >
                  <Link to="" className="thm-btn services-one__btn">
                    View More
                  </Link>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="services-one__single">
                  <div className="services-one__single-inner">
                    <div
                      className="services-one__single-bg"
                      style={{ backgroundImage: `url(${Image8})` }}
                    ></div>
                    <div className="services-one__icon">
                      <span className="icon-sustainable"></span>
                    </div>
                    <h3 className="services-one__title">
                      <Link to="services.html">Eco construction</Link>
                    </h3>
                    <p className="services-one__text">
                      There are many new ways of text.
                    </p>
                  </div>
                </div>
                <div
                  className="services-one__btn-box mt-3"
                  style={{ Display: "flex" }}
                >
                  <Link to="" className="thm-btn services-one__btn">
                    View More
                  </Link>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="services-one__single">
                  <div className="services-one__single-inner">
                    <div
                      className="services-one__single-bg"
                      style={{ backgroundImage: `url(${Image9})` }}
                    ></div>
                    <div className="services-one__icon">
                      <span className="icon-world"></span>
                    </div>
                    <h3 className="services-one__title">
                      <Link to="services.html">good locationn</Link>
                    </h3>
                    <p className="services-one__text">
                      There are many new ways of text.
                    </p>
                  </div>
                </div>
                <div
                  className="services-one__btn-box mt-3"
                  style={{ Display: "flex" }}
                >
                  <Link to="" className="thm-btn services-one__btn">
                    View More
                  </Link>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="services-one__single">
                  <div className="services-one__single-inner">
                    <div
                      className="services-one__single-bg"
                      style={{ backgroundImage: `url(${Image10})` }}
                    ></div>
                    <div className="services-one__icon">
                      <span className="icon-home-automation"></span>
                    </div>
                    <h3 className="services-one__title">
                      <Link to="">High technology</Link>
                    </h3>
                    <p className="services-one__text">
                      There are many new ways of text.
                    </p>
                  </div>
                </div>
                <div
                  className="services-one__btn-box mt-3"
                  style={{ Display: "flex" }}
                >
                  <Link to="" className="thm-btn services-one__btn">
                    View More
                  </Link>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="services-one__single">
                  <div className="services-one__single-inner">
                    <div
                      className="services-one__single-bg"
                      style={{ backgroundImage: `url(${Image11})` }}
                    ></div>
                    <div className="services-one__icon">
                      <span className="icon-electric-appliance"></span>
                    </div>
                    <h3 className="services-one__title">
                      <Link to="">luxury appliance</Link>
                    </h3>
                    <p className="services-one__text">
                      There are many new ways of text.
                    </p>
                  </div>
                </div>
                <div
                  className="services-one__btn-box mt-3"
                  style={{ Display: "flex" }}
                >
                  <Link to="" className="thm-btn services-one__btn">
                    View More
                  </Link>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="services-one__single">
                  <div className="services-one__single-inner">
                    <div
                      className="services-one__single-bg"
                      style={{ backgroundImage: `url(${Image12})` }}
                    ></div>
                    <div className="services-one__icon">
                      <span className="icon-swimming-pool"></span>
                    </div>
                    <h3 className="services-one__title">
                      <Link to="">swimming pool</Link>
                    </h3>
                    <p className="services-one__text">
                      There are many new ways of text.
                    </p>
                  </div>
                </div>
                <div
                  className="services-one__btn-box mt-3"
                  style={{ Display: "flex" }}
                >
                  <Link to="" className="thm-btn services-one__btn">
                    View More
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <section className="cta-two cta-four">
        <div className="cta-two__bg" style={{ backgroundImage: `url(${Image13})` }}>
          <div id="jarallax-container-0">
            <div style={{ backgroundImage: `url(${Image13})` }}></div>
          </div>
        </div>
        <div className="cta-two__inner">
          <h3 className="cta-two__title">Schedule a building visit</h3>
          <p className="cta-two__text">
            Lorem ipsum dolor sit amet, cotur adiping elit
          </p>
          <div className="cta-two__btn-box">
            <Link to="" className="cta-two__btn thm-btn">
              Send a request
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accomodation;
