import React from "react";
import Image8 from "../images/brand/brand-1-1.png";
import Image9 from "../images/brand/brand-1-2.png";
import Image10 from "../images/brand/brand-1-3.png";
import Image11 from "../images/brand/brand-1-4.png";
import Image12 from "../images/brand/brand-1-5.png";
import Image13 from "../images/brand/brand-1-6.png";
import Image6 from "../images/shapes/brand-one-shape-1.png";
import Image7 from "../images/shapes/brand-one-shape-2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
const Brand = () => {
  return (
    <>
      <section className="brand-one">
        <div className="brand-one__wrap">
          <div className="brand-one__shape-1 float-bob-y">
            <img src={Image6} alt="" />
          </div>
          <div className="brand-one__shape-2 float-bob-x">
            <img src={Image7} alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="brand-one__inner">
                  <div className="thm-swiper__slider swiper-container">
                    <Swiper
                      spaceBetween={50}
                      slidesPerView={3}
                      loop={true}
                      autoplay={{ delay: 8000 }}
                      speed={1500}
                      loopFillGroupWithBlank={true}
                      modules={[Autoplay]}
                      className="swiper-container thm-swiper__slider"
                    >
                      <SwiperSlide>
                        <img src={Image8} alt="" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={Image9} alt="" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={Image10} alt="" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={Image11} alt="" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={Image12} alt="" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img src={Image13} alt="" />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Brand;
