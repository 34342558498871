import React from "react";
import Img from "../images/backgrounds/testimonial-two-bg.png";

import T3 from "../images/testimonial/testimonial-1-4.jpg";
import T4 from "../images/testimonial/testimonial-1-5.jpg";
import T6 from "../images/testimonial/testimonial-1-6.jpg";

import D1 from "../images/testimonial/testimonial-client-1-2.jpg";
import D2 from "../images/testimonial/testimonial-client-1-3.jpg";

import S1 from "../images/shapes/testimonial-one-shape-1.png";
import S2 from "../images/shapes/testimonial-one-shape-2.png";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectFade } from "swiper";
const Feedback = () => {
  const Data = [
    {
      img: D1,
      name: "kevin Smith",
      shape: S1,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
    {
      img: D2,
      name: "kevin Smith",
      shape: S2,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
    {
      img: T3,
      name: "kevin Smith",
      shape: S1,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
    {
      img: T4,
      name: "kevin Smith",
      shape: S2,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
    {
      img: T6,
      name: "kevin Smith",
      shape: S1,
      title: "Marketing head",
      text: "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy data foster to collaborative thinking.",
    },
  ];
  return (
    <>
      <section class="testimonial-two">
        <div
          class="testimonial-two__bg"
          style={{ backgroundImage: `url(${Img})` }}
        ></div>
        <div class="container">
          <div class="testimonial-two__top">
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="testimonial-two__top-left">
                  <div class="section-title text-left">
                    <span class="section-title__tagline">Our Review</span>
                    <h2 class="section-title__title">
                      what they’re talking <br /> about us
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="testimonial-two__top-right">
                  <p class="testimonial-two__top-text">
                    Interior of volumes, space, air, proportion, with certain
                    light and mood. These interiors are meant to last forever.
                    You need to be sure there isn't anything embarrassing hidden
                    in the middle of text.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-two__bottom">
            <div class="testimonial-two__carousel thm-owl__carousel owl-theme owl-carousel">
              <Swiper
                spaceBetween={1}
                slidesPerView={1}
                loop={true}
                autoplay={{ delay: 8000 }}
                speed={1500}
                loopFillGroupWithBlank={true}
                modules={[Navigation, EffectFade, Autoplay]}
                className=""
              >
                {Data.map((e) => (
                  <SwiperSlide>
                    <div class="item">
                      <div class="testimonial-two__single">
                        <div class="testimonial-two__client-img-box">
                          <div class="testimonial-two__client-img">
                            <img src={e.img} alt="" />
                          </div>
                        </div>
                        <div class="testimonial-two__client-info">
                          <div class="testimonial-two__client-details">
                            <div class="testimonial-two__client-rating">
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                              <i class="fa fa-star"></i>
                            </div>
                            <h3 class="testimonial-two__client-name">
                              {e.title}
                            </h3>
                            <p class="testimonial-two__client-sub-title">
                              {e.name}
                            </p>
                          </div>
                          <div class="testimonial-two__quote">
                            <span class="icon-quote"></span>
                          </div>
                        </div>
                        <p class="testimonial-two__text">{e.text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Feedback;
